

<template>
  <v-app>
    

    <div class="image-container">
      <span v-for="(image, index) in images" :key="index">
        <div class="loading-container" v-if="!image.loaded">
          <img
            src="loading.gif"
            class="loading-gif"
          />
        </div>
        <img
          :src="image.src"
          v-else
          class="fullscreen-image"
        />
      </span>
    </div>
    
  </v-app>
</template>
<script>
export default {
  data() {
    return {
      images: [
  { src: require('@/assets/cat_img01.png'), loaded: false },
  { src: require('@/assets/cat_img02.png'), loaded: false },
  { src: require('@/assets/cat_img03.png'), loaded: false },
  { src: require('@/assets/cat_img04.png'), loaded: false },
  { src: require('@/assets/cat_img05.png'), loaded: false },
  { src: require('@/assets/cat_img06.png'), loaded: false },
  { src: require('@/assets/cat_img07.png'), loaded: false },
  { src: require('@/assets/cat_img08.png'), loaded: false },
  { src: require('@/assets/cat_img09.png'), loaded: false },
  { src: require('@/assets/cat_img10.png'), loaded: false },
  { src: require('@/assets/cat_img11.png'), loaded: false },
  { src: require('@/assets/cat_img12.png'), loaded: false },
  { src: require('@/assets/cat_img13.png'), loaded: false },
  { src: require('@/assets/cat_img15.png'), loaded: false },
  { src: require('@/assets/cat_img16.png'), loaded: false },
  { src: require('@/assets/cat_img17.png'), loaded: false },
  { src: require('@/assets/cat_img18.png'), loaded: false },
  { src: require('@/assets/cat_img19.png'), loaded: false },
  { src: require('@/assets/cat_img20.png'), loaded: false },
  { src: require('@/assets/cat_img21.png'), loaded: false },
  { src: require('@/assets/cat_img22.png'), loaded: false },
  { src: require('@/assets/cat_img23.png'), loaded: false },
  { src: require('@/assets/cat_img24.png'), loaded: false },
],

      overlay: true,
    };
  },
  mounted() {
    this.loadImages();
  },
  methods: {
    loadImages() {
      const promises = [
        this.createImagePromise(this.images[0]),
        this.createImagePromise(this.images[1]),
        this.createImagePromise(this.images[2]),
        this.createImagePromise(this.images[3]),
        this.createImagePromise(this.images[4]),
        this.createImagePromise(this.images[5]),
        this.createImagePromise(this.images[6]),
        this.createImagePromise(this.images[7]),
        this.createImagePromise(this.images[8]),
        this.createImagePromise(this.images[9]),
        this.createImagePromise(this.images[10]),
        this.createImagePromise(this.images[11]),
        this.createImagePromise(this.images[12]),
        this.createImagePromise(this.images[13]),
        this.createImagePromise(this.images[14]),
        this.createImagePromise(this.images[15]),
        this.createImagePromise(this.images[16]),
        this.createImagePromise(this.images[17]),
        this.createImagePromise(this.images[18]),
        this.createImagePromise(this.images[19]),
        this.createImagePromise(this.images[20]),
        this.createImagePromise(this.images[21]),
        this.createImagePromise(this.images[22]),
        this.createImagePromise(this.images[23]),
        // Restante das imagens...
      ];


      Promise.all(promises).then(() => {
        this.overlay = false; // Oculte o overlay de carregamento
      });
    },

    createImagePromise(image) {
      return new Promise(resolve => {
        const img = new Image();
        img.src = image.src;
        img.onload = () => {
          image.loaded = true;
          this.checkAllImagesLoaded();
          resolve();
        };
      });
    },

    checkAllImagesLoaded() {
      if (this.images.every(image => image.loaded)) {
        this.overlay = false; // Oculte o overlay de carregamento
      }
    },
  },
};
</script>

<style>
/* Estilo para o overlay de carregamento */
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

/* Estilo para a imagem ocupando a tela inteira */
.fullscreen-image {
  width: 100%;
  height: 100vh; /* Altura da janela */
  object-fit: cover; /* Mantém a proporção da imagem e preenche o espaço */
}

/* Estilo para o GIF de carregamento */
.loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%; /* Para ocupar a largura total do contêiner */
  height: 100vh; /* Altura da janela */
}

.loading-gif {
  max-width: 100px; /* Defina o tamanho adequado para o GIF de carregamento */
  height: auto;
}

/* Estilo para as imagens */
.image-container img {
  max-width: 100%;
  height: auto;
}
</style>
